const brandColors = {
  primary: '#0B0F19',
  secondary: '#B040F5',
};

const sidebarColors = {
  backgroundColor: '#0B0F19',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
